@media (min-width:1280px) and (max-width:1600px) {
    .menu-sec {
        padding: 30px 0px;
    }

    .menu-sec ul li a {
        line-height: 50px;
        padding: 0 10px;
    }

    .banner-sec {
        padding-left: 106px;
    }

    .banner-sec a.wallet-but {
        padding: 14px 25px;
    }

    ul.footer-social li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    ul.footer-social li {
        margin-left: 13px;
    }
    ul.footer-social li:first-child a{
        padding: 9px 0;
    font-size: 12px;
    line-height: 11px;

    }
    .banner-sec .wrapper:before {
        max-width: 500px;
        max-height: 500px;
    }

    .banner-sec .wrapper .sec1 {
        max-height: 500px;
        max-width: 500px;
        position: relative;
        margin: 50px auto;
    }

    .banner-sec .wrapper img.img2 {
        margin-left: 35px;
    }

    .banner-sec .wrapper .zebra-wrap.lt-sec {
        left: 60px;
    }

    .banner-sec .wrapper .zebra-wrap.rt-sec {
        right: 60px;
    }

    .banner-sec .wrapper img.main-logo {
        max-width: 600px;
    }

    .zebra-wrap {
        width: 100px;
        height: 300px;
    }

    .body-cont1 h2 {
        font-size: 400px;
        line-height: 600px;
    }

    .body-cont1 .sec1 .img-wrap {
        max-width: 238px;
    }

    .body-cont1 .sec1 img.img3 {
        max-width: 300px;
    }

    .body-cont1 .container-fluid {
        padding-right: 50px !important;
    }

    .body-cont1 .linesec {
        max-width: 750px;
    }

    .body-cont1 {
        margin-left: 106px;
        margin-top: 100px;
        margin-bottom: 150px;
        padding-left: 40px;
    }

    section.body-cont2 {
        position: relative;
        margin-left: 106px;
        padding: 0 40px;
    }

    section.body-cont2 h2 {
        font-size: 400px;
        line-height: 600px;
    }

    section.body-cont2 ul {
        margin-top: -250px;
        margin-bottom: 125px;
        padding: 0;
    }

    section.body-cont2 p {
        padding-right: 0;
    }

    section.body-cont2 .linesec {
        max-width: 586px;
    }

    section.body-cont2 a.mint-but {
        padding: 21px 30px;
    }

    section.body-cont3 {
        margin-left: 106px;
        padding-left: 40px;
    }

    section.body-cont3 .title-sec h2 {
        font-size: 389px;
        line-height: 300px;
    }

    section.body-cont3 img.img2 {
        max-width: 450px;
        bottom: -49px;
    }

    section.body-cont3 .linesec:before, section.body-cont3 .linesec:after {
        max-width: 880px;
    }

    section.body-cont3 .title-sec h4 {
        max-width: 900px;
        font-size: 24px;
        line-height: 67px;
        padding-right: 118px;
    }

    section.body-cont4 .title h2 {
        font-size: 400px;
        line-height: 600px;
    }

    section.body-cont4 {
        margin-left: 106px;
        padding-left: 40px;
    }

    section.body-cont4 .container-fluid {
        max-width: 1000px;
        margin-top: -173px;
        z-index: 999;
        position: relative;
    }

    section.body-cont4 .sec2 h2 {
        font-size: 50px;
        line-height: 60px;
    }

    section.body-cont4 .sec2 ul {
        margin: 7px 0 0;
    }

    section.body-cont4 .sec2 {
        padding: 15px;
    }

    section.body-cont4 .linesec {
        max-width: 828px;
        height: 470px;
        top: 85px;
        right: 78px;
    }

    section.body-cont4 a.mint-but {
        padding: 20px 35px;
    }

    section.body-cont5 h2 {
        font-size: 400px;
        line-height: 600px;
    }

    section.body-cont5 img.img3 {
        max-width: 550px;
        margin: 0 auto -100px;
        display: table;
    }

    section.body-cont5 .linesec {
        left: 191px;
        max-width: 784px;
    }

    section.body-cont5 .button-area1 {
        margin: 60px auto 0;
    }

    section.body-cont5 a.mint-but {
        padding: 21px 42px;
    }

    section.body-cont5 {
        position: relative;
        margin-left: 106px;
        padding-left: 40px;
        min-height: auto;
    }

    section.body-cont5 .title {
        position: absolute;
        top: 0;
        left: 40px;
    }

    section.body-cont6 .title h2 {
        font-size: 400px;
        line-height: 308px;
    }

    section.body-cont6 .wrap {
        width: 76%;
    }

    section.body-cont6 {
        margin-left: 106px;
        padding-left: 40px;
    }

    section.body-cont6 ul.sec1 {
        padding: 0;
        padding-right: 35px;
    }

    section.body-cont6 ul.sec1 li {
        padding: 15px;
        max-width: 19%;
    }

    section.body-cont6 ul.sec1 li img.img2 {
        max-width: 130px;
    }

    section.body-cont6 ul.sec1 li h2 {
        font-size: 50px;
        margin: 15px 0;
    }

    section.body-cont6 ul.sec1 li h4 {
        margin: 13px 0 15px;
        min-height: 50px;
    }

    section.body-cont6 ul.sec1 li p {
        min-height: 85px;
    }

    section.body-cont6 ul.sec1 li a.social-icon i.fab.fa-twitter {
        font-size: 13px;
        padding: 18px 27px;
    }

    section.body-cont6 a.mint-but {
        padding: 20px 65px;
    }

    section.body-cont6 .title {
        left: 40px;
    }

    .faq_sec {
        padding-left: 40px;
    }

    .faq_sec h2 {
        font-size: 400px;
        line-height: 600px;
    }

    .innerbanner img {
        max-width: 50%;
    }

    .faq_sec .accordion {
        width: 640px;
        margin: -299px auto 0;
    }

    .faq_sec .accordion-toggle {
        padding: 11px 18px;
    }

    .faq_sec .accordion-wrap {
        border-radius: 10px;
    }
    .rarity-cont h2 {
        font-size: 500px;
    }
    .rarity-cont .title img.main-img {
        position: absolute;
        top: 121px;
        right: -55%;
        max-width: 562px;
    }
    .rarity-cont .side-bar {
        max-width: 350px;
    }
    .rarity-cont .side-bar ul {
        padding: 0 22px 60px;
    }
    .rarity-cont .side-bar ul li a {
        font-size: 15px;
        padding: 16px 0;
    }
    .rarity-cont .decp-sec {
        max-width: calc(100% - 350px);
    }
    .rarity-cont .decp-sec .wrap2 {
        padding: 15px;
    }
    .rarity-cont .decp-sec .wrap2 h4 {
        font-size: 18px;
    }
    .advocate-cont h2 {
        font-size: 430px;
        line-height: 333px;
    }
    .advocate-cont .img1 {
        top: 8px;
        right: 297px;
        max-width: 224px
    }
    .advocate-cont .sec1 h4 {
        font-size: 50px;
    }
    .advocate-cont img.img2 {
        top: 125px;
        left: 600px;
        max-width: 100px;
    }
    .advocate-cont img.img3 {
        top: 285px;
        left: 429px;
        max-width: 224px;
    }
    .advocate-cont .title .img-sec {
        top: 50px;
    }
    .advocate-cont .img4 {
        left: 180px;
        top: 320px;
        max-width: 224px;
    
    }
    .advocate-cont img.img6 {
        left: 150px;
        max-width: 180px;
    }
    .advocate-cont .img5 {
        padding-left: 47px;
        max-width: 268px;
        margin: -169px auto 0;
    }
    .advocate-cont .button-area1 a.mint-but {
        padding: 25px 66px;
    }
    .advocate-cont .button-area1 {
        margin: 60px auto 110px;
    }
    
}


@media (max-width:767px) {
    .menu-sec {
        display: none;
    }

    .mob-menu {
        display: block;
        position: fixed;
        z-index: 9999;
        width: 100%;
    }

    .mob-menu .mob-wrap {
        background: url(../src/images/mob-menu-bg2.png);
        background-size: 100% 100%;
        width: 100%;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
    }

    a.close-menu {
        display: table;
        width: 100%;
        text-align: right;
        padding: 35px 25px;
    }

    .mob-menu ul.scrollspy {
        padding: 0 25px;
    }

    .mob-menu ul.scrollspy a {
        display: table;
        text-align: center;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #f3f6ff1a;
        padding: 12px;
    }

    .mob-menu ul.scrollspy li.current a {
        background: #1f1f23;
        color: #fff;
        background-image: url(../src/images/menu-active.svg);
        background-repeat: no-repeat;
        background-position: center left;
    }

    .mob-menu ul.footer-social2 {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px;
    }

    .mob-menu ul.footer-social2 li {
        margin: 0 10px;
    }

    .mob-menu ul.footer-social2 li a {
        display: table;
        width: 48px;
        height: 48px;
        line-height: 48px;
        background-image: url(../src/images/social-bg1.svg);
        position: relative;
        text-align: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .mob-menu ul.footer-social2 li:first-child a{
        padding: 9px 0;
    font-size: 12px;
    line-height: 11px;
        color: #fff;
        text-transform: uppercase;
    }
    .mob-menu ul.footer-social2 li a:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../src/images/social-bg2.svg);
        opacity: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .mob-menu ul.footer-social2 li a:hover:before {
        opacity: 1;
    }

    .mob-menu a.wallet-but2 {
        background: linear-gradient(180deg, #e5e5e50d 0%, #df15730d 100%);
        display: table;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #f3f6ff;
        text-decoration: none;
        padding: 22px 36px;
        -webkit-clip-path: polygon(15% 0%, 100% 0, 100% 70%, 88% 100%, 0 100%, 0% 25%);
        clip-path: polygon(15% 0%, 100% 0, 100% 70%, 88% 100%, 0 100%, 0% 25%);
        border-radius: 0 15px 0 15px;
        z-index: 9999;
        margin: 33px auto 0px;
    }

    a.me-log {
        display: table;
        width: 100%;
        text-align: center;

        background-image: url(../src/images/mob-menu-bg.png);
        padding: 30px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        /* margin-bottom: -94px; */
    }

    .banner-sec a.wallet-but {
        display: none;
    }

    .banner-sec a.mob-menu-icon {
        display: block;
        max-width: 24px;
        position: fixed;
        right: 20px;
        top: 44px;
        z-index: 9999;
    }

    div#root {
        overflow: hidden;
    }

    .mob-menu.current {

        z-index: 999999;
    }

    .mob-menu.current .mob-wrap {
        max-height: 600px;
        transition: max-height 0.25s ease-in;
    }
}

@media (max-width:767px) {
    .social-wrap {
        display: none;
    }

    .banner-sec {
        padding-left: 0;
    }

    .banner-sec .wrapper:before {
        max-width: 350px;
        max-height: 350px;
    }

    .banner-sec .wrapper .sec1 {
        max-height: 350px;
        max-width: 350px;
    }

    .banner-sec .wrapper img.img2 {
        margin-left: 20px;
    }

    .zebra-wrap {
        width: 70px;
        height: 225px;
    }

    .banner-sec .wrapper .zebra-wrap.lt-sec {
        left: 67px;
    }

    .banner-sec .wrapper .zebra-wrap.rt-sec {
        right: 67px;
    }

    .banner-sec .wrapper img.main-logo {
        max-width: 350px;
        margin: 30px 0 15px;
    }

    .banner-sec .wrapper .sec2 {
        margin-top: -141px;
    }

    .banner-sec .wrapper {
        padding: 125px 0 0;
    }

    .banner-sec .wrapper .sec2 a.mint-but {
        font-weight: 500;
        font-size: 14px;
        padding: 19px 53px;
    }

    .body-cont1 {
        position: relative;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 0px;
    }

    .body-cont1 h2 {
        font-size: 149px;
        line-height: 520px;
        padding-left: 35px;
    }

    .body-cont1 .sec1 .img-wrap {
        right: 20px;
        max-width: 247px;
    }

    .body-cont1 .sec1 img.img3 {
        max-width: 247px;
    }

    .body-cont1 p {
        margin: 20px 0 0;
        padding: 0 20px;
    }

    .body-cont1 .container-fluid {
        position: relative;
    }

    .body-cont1 .linesec {
        position: absolute;
        max-width: 114px;
        z-index: 999;
        opacity: 0.6;
        top: -75px;
        left: 75px;
    }

    .body-cont1 .linesec span.line1 {
        width: 1px;
    }

    .body-cont1.active .linesec span.line1 {
        height: 295px;
        transition: all 0.3s ease-in-out;
    }

    .body-cont1.active .linesec span.line3 {
        height: 20px;
        transition-delay: 0.8s;
    }

    .body-cont1 .linesec span.line2 {
        display: table;
        width: 0;
        border: none;
        height: 1px;
    }

    .body-cont1.active .linesec span.line2 {
        width: 100%;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.3s;
        background: #fff;
    }

    .body-cont1 .title .img-sec {
        position: absolute;
        bottom: 52px;
        left: 15px;
    }

    .body-cont1 .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    .body-cont1 .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    section.body-cont2 {
        margin-left: 0;
        margin-top: 100px;
    }

    section.body-cont2 .title {
        float: left;
        margin-bottom: 30px;
        width: 100%;
    }

    section.body-cont2 .title .img-sec {
        bottom: unset;
        left: 15px;
        top: 45px;
    }

    section.body-cont2 .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    section.body-cont2 .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    section.body-cont2 ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        flex-direction: column;
        align-items: center;
    }

    section.body-cont2 ul li {
        margin: 0 0 30px;
    }

    section.body-cont2 h2 {
        font-size: 149px;
        line-height: 150px;
        padding-left: 30px;
    }

    section.body-cont2 p {
        margin: 20px 0 0;
        padding: 0 15px;
    }

    section.body-cont2 .linesec {
        max-width: 69%;
        width: 100%;
        bottom: unset;
        right: 25px;
        height: 1250px
    }

    section.body-cont2 .linesec span.line1 {
        height: 0;
        right: unset;
        top: 0;
        left: 0;
    }

    section.body-cont2 .linesec span.line4 {
        width: 0;
        height: 1px;
        background: #fff;
        position: absolute;
        right: 0;
        bottom: -20px;
    }

    section.body-cont2.active .linesec span.line4 {
        width: 65%;
    }

    section.body-cont2.active .linesec span.line4:after {
        content: "";
        width: 1px;
        height: 0px;
        background: #fff;
        position: absolute;
        top: 0;
    }

    section.body-cont2.active .linesec span.line4:after {
        height: 20px;
    }

    section.body-cont2.active .linesec span.line1 {
        height: 20px;
    }

    section.body-cont2 .linesec span.line2 {
        bottom: unset;
        height: 1px;
        top: 20px;
        right: unset;
        left: 0;
    }

    section.body-cont2 .linesec span.line3 {
        top: 20px;
        left: unset;
        right: 0;
    }

    section.body-cont2.active .linesec span.line3 {
        height: 100%;
    }

    section.body-cont2 .button-area1 {
        display: none;
    }

    section.body-cont3 {
        margin-left: 0;
        margin-top: 25px;
        margin-bottom: 0;
    }

    section.body-cont3 .title-sec h2 {
        font-size: 133px;
        line-height: 200px;
    }

    section.body-cont3 img.img2 {
        bottom: -15px;
    }

    section.body-cont3 .title-sec h4 {
        width: 90%;
        max-width: 100%;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        padding-right: 0;
        margin-top: 35px;
    }

    section.body-cont3.active .linesec:before {
        width: 92%;
    }

    section.body-cont3 .linesec:after {
        width: 92%;
    }

    section.body-cont4 {
        position: relative;
        margin-left: 0;
        margin-bottom: 0;
    }

    section.body-cont4 .title {
        position: relative;
        width: auto;
        clear: both;
        display: table;
        margin: 0 auto;
        width: 100%;
        padding-left: 30px;
    }

    section.body-cont4 .title h2 {
        font-size: 149px;
        line-height: 150px;
    }

    section.body-cont4 .title .img-sec {
        bottom: unset;
        left: 15px;
        top: 45px;
    }

    section.body-cont4 .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    section.body-cont4 .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    section.body-cont4 .container-fluid {
        margin-top: 0;
        padding: 30px;
    }

    section.body-cont4 .sec2 {
        padding: 19px;
    }

    section.body-cont4 .sec2 h2 {
        font-size: 60px;
        line-height: 73px;
        margin: 0;
    }

    section.body-cont4 .sec2 h3 {
        font-size: 14px;
        line-height: 25px;
        margin: 0;
    }

    section.body-cont4 .sec2 ul {
        margin: 9px 0;
    }

    section.body-cont4 .sec1, section.body-cont4 .sec2 {
        margin-bottom: 25px;
        background-repeat: no-repeat;
    }

    section.body-cont4 .aos-init .row {
        justify-content: center;
    }

    section.body-cont4 .linesec {
        max-width: 47%;
        height: 70%;
        width: 100%;
        position: absolute;
        right: 15px;
        margin: auto;
        opacity: 0.6;
        top: 165px;
    }

    section.body-cont4 .linesec span.line1 {
        top: -15px;
    }

    section.body-cont4.active .linesec span.line1 {
        height: 15px;
    }

    section.body-cont4.active .linesec span.line4 {
        width: 100%;
    }

    section.body-cont4 .linesec span.line4:before {
        content: "";
        width: 1px;
        height: 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #fff;
    }

    section.body-cont4.active .linesec span.line4:before {
        height: 20px;
    }

    section.body-cont5 {
        position: relative;
        margin-left: 0;
        min-height: unset;
    }

    section.body-cont5 .linesec {
        display: none;
    }

    section.body-cont5 h2 {
        font-size: 149px;
        line-height: 150px;
        padding-left: 30px;
    }

    section.body-cont5 .title .img-sec {
        bottom: unset;
        top: 30px;
        left: 10px;
    }

    section.body-cont5 .title .img-sec {
        bottom: unset;
        left: 15px;
        top: 45px;
    }

    section.body-cont5 .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    section.body-cont5 .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    section.body-cont5 .wrap {
        position: relative;
        padding-top: 98px;
    }

    section.body-cont5 img.img3 {
        max-width: 350px;
        margin: 0 auto;
        display: table;
    }

    section.body-cont5 p {
        margin: 0 0 25px;
        padding: 0 20px;
    }

    section.body-cont5 .title {
        top: 26px;
    }

    section.body-cont5 picture {
        position: absolute;
        top: 0;
        width: 100%;
    }

    section.body-cont6 {
        margin-left: 0;
        padding-top: 0;
    }

    section.body-cont6 .linesec {
        display: none;
    }

    section.body-cont6 .title {
        position: relative;
        top: 0;
        left: 0;
    }

    section.body-cont6 .title .img-sec {
        bottom: unset;
        left: 15px;
        top: 45px;
    }

    section.body-cont6 .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    section.body-cont6 .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    section.body-cont6 .title h2 {
        font-size: 149px;
        line-height: 150px;
        padding-left: 30px;
    }

    section.body-cont6 .wrap {
        width: 100%;
        padding: 0 30px;
    }

    section.body-cont6 ul.sec1 {
        flex-direction: column;
        padding: 0;
        max-width: 300px;
        margin: 0 auto;
    }

    section.body-cont6 ul.sec1 li {
        margin: 0 0 45px;
    }

    section.body-cont6 .button-area1 {
        display: table;
        margin: 30px auto 0;
        position: relative;
    }

    .footer-sec {
        padding: 25px 0;
        text-align: center;
        width: 90%;
        margin: 55px auto 0;
    }

    .body-cont1 img.img1, section.body-cont3 img.img1, section.body-cont5 img.img2 {
        width: 100%;
    }

    .faq_sec {
        margin: 30px;
    }

    .faq_sec .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
        width: 62px;
        height: 204px;
    }

    .faq_sec .title h2 {
        font-size: 149px;
        line-height: 150px;
        padding-left: 30px;
    }

    .faq_sec .title .img-sec {
        position: absolute;
        bottom: unset;
        left: 0;
        top: 43px;
    }

    .faq_sec .accordion {
        width: 100%;
        margin: 65px auto 0;
    }

    .faq_sec .title {
        position: relative;
        margin-top: 10px;
        margin-left: 0;
    }

    .faq_sec .accordion-toggle span {
        margin-right: 3px;
        font-size: 13px;
        font-weight: 400;
        position: absolute;
        left: 15px;
    }

    .faq_sec .accordion-toggle:before {
        font-size: 15px;
        width: 52px;
    }

    .faq_sec .accordion-toggle {
        padding-right: 66px;
        padding-left: 30px;
    }

    .wallet-wrap img.main-img {
        display: none;
    }

    .wallet-wrap {
        position: relative;
        padding: 50px 0;
        background: url(../src/images/mint-mob-bg.png);
        background-size: 50%;
        background-repeat: no-repeat;
    }

    .wallet-wrap .sec1 {
        position: relative;
        max-width: 320px;
        margin: 0 auto;
    }
    .rarity-cont {
        margin-left: 0;
        margin-top: 60px;
    }
    

    .rarity-cont .title .img-sec {
        bottom: unset;
        left: 15px;
        top: 45px;
    }

    .rarity-cont .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    .rarity-cont .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    .rarity-cont .title h2 {
        font-size: 149px;
        line-height: 150px;
        padding-left: 30px;
    }
    .rarity-cont .title {
        width: 100%;
        margin: 0 0 147px;
    }
    .rarity-cont .title img.main-img {
        position: absolute;
        top: 0;
        right: 15px;
        max-width: 231px;
    }
    .rarity-cont .linesec {
        max-width: 50px;
        height: 151px;
        bottom: -133px;
        left: 79px;
    }
    .rarity-cont .side-bar ul li a {
        padding: 15px 0;
    }
    .rarity-cont .side-bar {
        padding-right: 0;
        max-width: 100%;
        width: 90%;
        margin: 0 auto 100px;
    }
    .rarity-cont .decp-sec {
        max-width: 100%;
        width: 90%;
        margin: 0 auto;
    }
    .rarity-cont .decp-sec h3 {
        font-size: 72px;
    }
    .rarity-cont .decp-sec .wrap2 h4 {
        font-size: 24px;
        line-height: 30px;
        margin: 24px 0 15px;
        flex-direction: column;
    }
    .rarity-cont .decp-sec .wrap2 {
        height: auto;
        margin: 0 0 30px;
    }
    .advocate-cont {
        margin-left: 0;
        padding-top: 100px;
        max-width: 320px;
    margin: 0 auto;
    }

    .advocate-cont .title .img-sec {
        bottom: unset;
        left: 0px;
        top: 20px;
    }

    .advocate-cont .title h6 {
        font-size: 12px;
        line-height: 12px;
    }

    .advocate-cont .title .zebra-wrap {
        transform: scale(0.7);
        transform-origin: left top;
    }

    .advocate-cont .title h2 {
        font-size: 149px;
        line-height: 119px;
        padding-left: 30px;
        padding-right: 0;
    }
    .advocate-cont .img1, .advocate-cont .img4, .advocate-cont img.img4 {
        display: none;
    }
    .advocate-cont img.img2 {
        top: -39px;
        left: unset;
        right: 0;
        max-height: 96px;
    }
    .advocate-cont img.img6 {
        position: absolute;
        left: 0;
        max-width: 110px;
        margin-top: 23px;
    }
    .advocate-cont .sec1 h4 {
        font-size: 45px;
        line-height: 72px;
        padding: 0px 0 0px;
    }
    .advocate-cont .img5 {
        display: block;
        margin: -115px auto 0;
        position: relative;
        padding-left: 0;
        float: right;
        max-width: 144px;
        margin-right: 25px;
    }
    .advocate-cont .button-area1 a.mint-but {
        padding: 26px 60px;
        margin-top: 50px;
    }
    .advocate-cont .button-area1 {
        margin: 60px auto 100px;
    }

    section.advocate-cont.dream-page {
        padding-top: 0;
        padding-bottom: 50px;
        margin-left: 0;
        display: table;
        width: 100%;
        margin: auto;
    }
    section.advocate-cont.dream-page ul.dream-sec {
        display: table;
        margin: 0 auto;
    }
}